<template>
  <div class="container" v-loading="containerLoading">
    <div class="topHeader">
      <div class="bannerTit">
        <span>竞拍项目</span>
      </div>
    </div>
    <div class="projectContent">
      <div class="toDay">
        <div class="smallHeader" v-if="nowItems.length != 0">
          <div class="tit">今日拍卖</div>
          <!-- <div class="line"></div> -->
        </div>

        <div class="inProgress" v-if="nowItems.length != 0">
          <!-- <div class="tit">正在拍卖</div> -->
          <div class="content">
            <div class="img">
              <img :src="form.url" alt="" />
            </div>
            <div class="contentDetail">
              <div class="header">
                <div class="status">正在拍卖</div>

                <div class="title">
                  {{ form.auctionTitle }}
                </div>
              </div>

              <div class="prices">
                <div class="left">
                  <div class="bzj">
                    <div class="icon">
                      <img src="@/assets/images/bidProject/bzj.png" alt="" />
                    </div>
                    <div class="wz">保证金</div>
                    <div class="price">
                      ￥{{ formatMoneyString(form.deposit) }}
                    </div>
                  </div>
                  <!-- <div class="pg">
                    <div class="icon">
                      <img src="@/assets/images/bidProject/pg.png" alt="" />
                    </div>
                    <div class="wz">评估价</div>
                    <div class="price">
                      ￥{{
                        form.marketValuation
                          ? formatMoneyString(form.marketValuation)
                          : "--"
                      }}
                    </div>
                  </div> -->
                  <div class="dq">
                    <div class="icon">
                      <img src="@/assets/images/bidProject/dq.png" alt="" />
                    </div>
                    <div class="wz">起拍价</div>
                    <div
                      class="price price1"
                      v-if="
                        form.auctionStatus == '0' && form.isPreQuotation == 'Y'
                      "
                      style="font-size: 14px"
                    >
                      开拍前公布
                    </div>
                    <div class="price price1" v-else>
                      <span>{{ formatMoneyString(form.startingPrice) }}元</span>
                      <span v-if="form.quoting == 1"
                        >/{{ form.quotationUnit }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="bd">
                    <div class="wz">拍卖标的</div>
                    <div class="pub pmbd">
                      {{ form.targetName }}
                      <span v-if="form.quoting == 1">
                        {{ form.transactionQuantity }}
                        {{ form.quotationUnit }}
                      </span>
                    </div>
                  </div>
                  <div class="qp">
                    <div class="wz">起拍时间</div>
                    <div class="pub">
                      {{ `${form.dateDay}&nbsp;${form.auctionStartTime}` }}
                    </div>
                  </div>
                  <!-- <div class="js">
                    <div class="wz jjs">距结束</div>
                    <div class="pub">
                      {{ form.djs }}
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="tel">
                <span>拍卖咨询</span>
                <span>010-68942796</span>
                <!-- <span>010-1234567</span> -->
              </div>
              <div class="btn">
                <div class="img1">
                  <img src="@/assets/images/bidProject/biaoqian.png" alt="" />
                  <el-button
                    @click="handleView('', form.auctionCode)"
                    size="medium"
                    type="success"
                    >项目详情</el-button
                  >
                </div>
                <div>
                  <el-button
                    size="medium"
                    type="success"
                    plain
                    @click="viewNotice(form.auctionCode)"
                    >查看公告</el-button
                  >
                </div>
                <div>
                  <el-button
                    size="medium"
                    type="success"
                    plain
                    @click="viewRules(form.auctionCode)"
                    >规则说明</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="beginMinute"
          v-if="todayItems.length != 0 || todayItemsVisible"
        >
          <div class="search">
            <div class="title">即将开始</div>
            <div class="queryParams">
              <el-input
                size="small"
                placeholder="查询拍卖标题，拍卖编号"
                v-model="todaySearch"
                class="input-with-select"
                clearable
                @clear="handleSearch('today')"
              >
                <el-button slot="append" @click="handleSearch('today')">
                  <SvgIcon iconClass="search" />
                </el-button>
              </el-input>
            </div>
          </div>

          <div class="timeLine" v-if="todayItems.length != 0">
            <el-timeline>
              <el-timeline-item
                v-for="(item, index) in todayItems"
                :key="index"
                :timestamp="item.auctionStartTime"
                placement="top"
                color="#0DAC65"
              >
                <div>
                  <div class="content">
                    <div class="leftImg">
                      <img :src="item.url" alt="" />
                    </div>
                    <div class="rightCon">
                      <div class="rightTit">
                        <!-- <div class="rightStatus">即将开始</div> -->
                        <div class="rightTitle">
                          {{ item.auctionTitle }}
                        </div>
                      </div>
                      <div class="rightPrice">
                        <div class="prices">
                          <div class="rightPub">
                            <div class="icon">
                              <img
                                src="@/assets/images/bidProject/bzj.png"
                                alt=""
                              />
                            </div>
                            <div class="wz">保证金</div>
                            <div class="price">
                              ￥{{ formatMoneyString(item.deposit) }}
                            </div>
                          </div>
                          <div class="rightPub">
                            <div class="icon">
                              <img
                                src="@/assets/images/bidProject/pg.png"
                                alt=""
                              />
                            </div>
                            <div class="wz">评估价</div>
                            <div
                              v-if="
                                item.auctionStatus == '0' &&
                                  item.isPreQuotation == 'Y'
                              "
                                 class="price"
                            >
                              开拍前公布
                            </div>
                            <div class="price" v-else>
                              {{
                                item.marketValuation
                                  ? formatMoneyString(item.marketValuation) +
                                    "元"
                                  : "--"
                              }}
                              <span v-if="item.quoting && item.marketValuation"
                                >/{{ item.quotationUnit }}</span
                              >
                            </div>
                          </div>
                          <div class="rightPub">
                            <div class="wz">交易数量</div>
                            <div class="price">
                              <span
                                >{{
                                  item.quoting == 1
                                    ? item.transactionQuantity
                                    : "--"
                                }}{{ item.quotationUnit }}</span
                              >
                            </div>
                          </div>
                          <div class="rightPub">
                            <div class="icon">
                              <img
                                src="@/assets/images/bidProject/dq.png"
                                alt=""
                              />
                            </div>
                            <div class="wz">起拍价</div>
                            <div
                              class="price price1"
                              v-if="
                                item.auctionStatus == '0' &&
                                  item.isPreQuotation == 'Y'
                              "
                              style="font-size: 14px"
                            >
                              开拍前公布
                            </div>
                            <div class="price price1" v-else>
                              <span
                                >{{
                                  formatMoneyString(item.startingPrice)
                                }}元</span
                              >
                              <span v-if="item.quoting == 1"
                                >/{{ item.quotationUnit }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          class="detailBtn"
                          @click="handleView('', item.auctionCode)"
                        >
                          项目详情
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>

          <div class="empty" v-else>暂无数据</div>
        </div>
      </div>
    </div>

    <div
      class="tomorrowBox"
      v-if="this.tomorrowItems.length != 0 || tomorrowItemsVisible"
    >
      <div class="tomorrow">
        <div class="tomorrowHeader">
          <div class="tomorrowTitle">
            <div class="title">明日及以后</div>
            <div class="search">
              <el-input
                size="small"
                placeholder="查询拍卖标题"
                v-model="tomorrowSearch"
                class="input-with-select"
                clearable
                @clear="handleSearch('tomorrow')"
              >
                <el-button slot="append" @click="handleSearch('tomorrow')">
                  <SvgIcon iconClass="search" />
                </el-button>
              </el-input>
            </div>
          </div>
          <div class="line"></div>
        </div>

        <div class="tomorrowContent" v-if="tomorrowItems.length != 0">
          <div
            class="contentBox"
            v-for="(item, index) in tomorrowItems"
            :key="index"
            @click="handleView('', item.auctionCode)"
          >
            <div class="imgStyle">
              <img :src="item.url" alt="" />
            </div>

            <div class="title">
              <span class="status">即将开始</span>
              <span class="titleStyle">{{ item.auctionTitle }}</span>
            </div>

            <div class="contentPrice">
              <div class="qp public">
                <div class="icon">
                  <img src="@/assets/images/bidProject/dq.png" alt="" />
                </div>
                <div class="wz">起拍价</div>

                <div
                  class="price price1"
                  v-if="item.auctionStatus == '0' && item.isPreQuotation == 'Y'"
                  style="font-size: 14px"
                >
                  开拍前公布
                </div>
                <div class="price price1" v-else>
                  <span>{{ formatMoneyString(item.startingPrice) }}元</span>
                  <span v-if="item.quoting">/{{ item.quotationUnit }}</span>
                </div>
              </div>
              <div class="pg public">
                <div class="icon">
                  <img src="@/assets/images/bidProject/pg.png" alt="" />
                </div>
                <div class="wz">评估价</div>
                <div
                  class="price price1"
                  v-if="item.auctionStatus == '0' && item.isPreQuotation == 'Y'"
                  style="font-size: 14px"
                >
                  开拍前公布
                </div>
                <div class="price" v-else>
                  {{
                    item.marketValuation
                      ? formatMoneyString(item.marketValuation)
                      : "--"
                  }}
                  <span v-if="item.quoting == 1 && item.marketValuation"
                    >/{{ item.quotationUnit }}</span
                  >
                </div>
              </div>
              <div class="bzj public">
                <div class="icon">
                  <img src="@/assets/images/bidProject/bzj.png" alt="" />
                </div>
                <div class="wz">保证金</div>
                <div class="price">￥{{ formatMoneyString(item.deposit) }}</div>
              </div>
              <div class="qpTime public">
                <div class="icon">
                  <img src="@/assets/images/bidProject/time.png" alt="" />
                </div>
                <div class="wz">起拍时间</div>
                <div class="price">
                  {{ `${item.dateDay}&nbsp;${item.auctionStartTime}` }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="more"
            v-if="
              this.tomorrowSum.length > 8 &&
                this.tomorrowSum.length != this.tomorrowItems.length
            "
            @click="moreDetail()"
          >
            更多<span style="color: #0dac65">{{
              this.tomorrowSum.length - 8
            }}</span
            >件
          </div>
        </div>
        <div class="empty" v-else>暂无数据</div>
      </div>
    </div>
    <div class="endBidContent">
      <div class="endBidBox">
        <div class="endBidHeader">
          <div class="endBidTitle">
            <div class="endBidWz">已结束竞价</div>
            <div class="search">
              <el-input
                size="small"
                placeholder="查询拍卖标题，拍卖编号"
                v-model="queryParams.auctionTitle"
                class="input-with-select"
                clearable
                @clear="handleQuery()"
              >
                <el-button slot="append" @click="handleQuery()">
                  <SvgIcon iconClass="search" />
                </el-button>
              </el-input>
            </div>
          </div>

          <!-- <div class="line"></div> -->
        </div>

        <div class="content">
          <el-table
            id="tableData"
            v-loading="tableLoading"
            :data="dataList"
            style="width: 100%"
            :header-cell-style="{
              background: '#F5F5F5',
              fontWeight: 600,
              fontSize: '14px',
              color: '#000000d9',
            }"
          >
            <el-table-column
              v-for="(item, index) in tableHeader"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :min-width="item.width ? item.width : 'auto'"
              align="left"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <div
                  v-if="item.prop == 'auctionStatus_ch'"
                  class="auctionStatus"
                >
                  <div
                    class="circle"
                    :class="{
                      circle1: scope.row.auctionStatus == '4',
                      circle2: scope.row.auctionStatus == '5',
                      circle3:
                        scope.row.auctionStatus != '2' &&
                        scope.row.auctionStatus != '4' &&
                        scope.row.auctionStatus != '5',
                    }"
                  ></div>
                  {{ scope.row.auctionStatusCopy }}
                </div>
                <div v-if="item.prop == 'startingPrice_cn'">
                  {{
                    scope.row.quoting == 1
                      ? scope.row.startingPrice + "/" + scope.row.quotationUnit
                      : scope.row.startingPrice
                  }}
                </div>
                <div v-if="item.prop == 'transactionQuantity_cn'">
                  <span
                    >{{
                      scope.row.quoting == 1
                        ? scope.row.transactionQuantity
                        : "--"
                    }}{{ scope.row.quotationUnit }}</span
                  >
                </div>
                <div v-else>{{ scope.row[item.prop] }}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="left"
              width="100"
              fixed="right"
              prop="action"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleView(scope.$index, scope.row.auctionCode)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              @size-change="handleSizeChange()"
              @current-change="handleCurrentChange()"
              :current-page.sync="queryParams.pageNum"
              :page-size.sync="queryParams.pageSize"
              :page-sizes="[10, 50, 100, 200]"
              layout="total, prev, pager, next,sizes, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { getAuctionItemList, getHistoryList } from "@/api/bidProject";
export default {
  name: "Project",
  data() {
    return {
      baseUrl: "/api",
      containerLoading: false,
      tableLoading: false,
      form: {},
      input3: "",
      tomorrowSearch: "", //明日查询框
      todaySearch: "", //明日查询框
      tableHeader: [
        {
          label: "拍卖编号",
          prop: "auctionCode",
          width: 110,
        },
        {
          label: "拍卖项目名称",
          prop: "auctionTitle",
          width: 250,
        },
        {
          label: "起拍价（元）",
          prop: "startingPrice_cn",
          width: 120,
        },
        {
          label: "交易数量",
          prop: "transactionQuantity_cn",
          width: 100,
        },
        {
          label: "拍卖结束时间",
          prop: "transactionTime",
          width: 170,
        },
        {
          label: "拍卖用时",
          prop: "bidTime",
          width: 110,
        },
        {
          label: "有效出价",
          prop: "effectiveNum",
          width: 80,
        },
        {
          label: "竞拍结果",
          prop: "auctionStatus_ch",
          width: 110,
        },
      ],

      queryParams: {
        pageNum: 1,
        pageSize: 10,
        auctionCode: "",
        auctionTitle: "",
      },

      nowItems: [], //正在拍卖数组
      todayItems: [], //即将开始数组
      tomorrowItems: [], //明日及以后数组
      dataList: [], //已结束数组
      todayItemsVisible: false, //即将开始显示与隐藏
      tomorrowItemsVisible: false, //明日及以后显示与隐藏
      total: 0,
      tomorrowSum: [], //明日及以后总数组用于区分超过8条折叠显示
    };
  },
  mounted() {
    this.getAuctionItemList();
    this.getList();
    this.$forceUpdate();
  },
  methods: {
    // 获取今日明日
    getAuctionItemList() {
      this.containerLoading = true;
      getAuctionItemList().then((res) => {
        if (res.code == 200) {
          this.nowItems = res.data.nowItems.map((item) => {
            item.url =
              this.baseUrl +
              "/file/displayResource?objectName=" +
              item.objectNames[0];
            return item;
          });
          this.form = { ...this.nowItems[0] };
          this.todayItems = res.data.todayItems;
          this.todayItems.forEach((item) => {
            item.url =
              this.baseUrl +
              "/file/displayResource?objectName=" +
              item.objectNames[0];
          });
          this.tomorrowSum = res.data.tomorrowItems.map((item) => {
            item.url =
              this.baseUrl +
              "/file/displayResource?objectName=" +
              item.objectNames[0];
            return item;
          });
          //小于等于8条直接展示
          if (this.tomorrowSum.length <= 8) {
            this.tomorrowItems = this.tomorrowSum;
          } else {
            for (let i = 0; i < 8; i++) {
              this.tomorrowItems.push(this.tomorrowSum[i]);
            }
          }
          this.containerLoading = false;
        }
      });
    },

    // 当前页
    handleCurrentChange(val) {
      this.getList();
    },
    // 每页几条
    handleSizeChange(val) {
      this.getList();
    },
    // 获取已结束
    getList() {
      this.tableLoading = true;
      this.queryParams.auctionCode = this.queryParams.auctionTitle;
      getHistoryList(this.queryParams).then((res) => {
        if (res.code == 200) {
          this.dataList = res.rows.map((item) => {
            item.dateDayStartTime = item.dateDay + " " + item.auctionEndTime;
            item.startingPrice = this.formatMoneyString(item.startingPrice);
            switch (item.auctionStatus) {
              case "0":
                item.auctionStatusCopy = "即将开始";
                break;
              case "1":
                item.auctionStatusCopy = "正在拍卖";
                break;
              case "2":
                item.auctionStatusCopy = "已成交";
                break;
              case "3":
                item.auctionStatusCopy = "已流拍";
                break;
              case "4":
                item.auctionStatusCopy = "已取消";
                break;
              case "5":
                item.auctionStatusCopy = "已中止";
                break;
              case "6":
                item.auctionStatusCopy = "已支付";
                break;
              case "7":
                item.auctionStatusCopy = "合同签署完成";
                break;
              default:
                item.auctionStatusCopy = "";
                break;
            }
            if (item.auctionStatus == "5") {
              item.transactionTime = item.updateTime;
            }
            if (item.auctionStatus == "4") {
              item.transactionTime = "---";
              item.bidTime = "---";
              item.effectiveNum = "---";
            }

            if (item.auctionStatus != "4") {
              const startTime = item.dateDay + " " + item.auctionStartTime;
              const endTime = item.transactionTime;

              item.bidTime = this.calculateTimeDifference(startTime, endTime);
            }
            return item;
          });
          this.total = res.total;
          this.tableLoading = false;
        }
      });
    },

    calculateTimeDifference(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      const timeDifference = Math.abs(endDate - startDate);

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      return `${this.formatTwoDigits(hours)}时${this.formatTwoDigits(
        minutes
      )}分${this.formatTwoDigits(seconds)}秒`;
    },

    formatTwoDigits(value) {
      return value < 10 ? `0${value}` : `${value}`;
    },

    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    handleView(index, data) {
      this.$router.push({
        path: "biddingProject/details",
        query: {
          auctionCode: data,
        },
      });
    },

    // 今日明日查询
    handleSearch(type) {
      let params = {
        auctionCode: type == "today" ? this.todaySearch : this.tomorrowSearch,
        auctionTitle: type == "today" ? this.todaySearch : this.tomorrowSearch,
      };
      getAuctionItemList(params).then((res) => {
        if (res.code == 200) {
          if (type == "today") {
            this.todayItems = res.data.todayItems.map((item) => {
              item.url =
                this.baseUrl +
                "/file/displayResource?objectName=" +
                item.objectNames[0];
              return item;
            });
            this.todayItemsVisible = true;
          } else if (type == "tomorrow") {
            this.tomorrowItemsVisible = true;
            this.tomorrowSum = res.data.tomorrowItems.map((item) => {
              item.url =
                this.baseUrl +
                "/file/displayResource?objectName=" +
                item.objectNames[0];
              return item;
            });

            //小于等于8条直接展示
            if (this.tomorrowSum.length <= 8) {
              this.tomorrowItems = this.tomorrowSum;
            } else {
              for (let i = 0; i < 8; i++) {
                this.tomorrowItems.push(this.tomorrowSum[i]);
              }
            }
          }

          this.containerLoading = false;
        }
      });
    },

    // 明日及以后点击更多
    moreDetail() {
      this.tomorrowItems = this.tomorrowSum;
    },
    // 金额添加千分符
    // formatMoneyString(moneyString) {
    //   // 将金额字符串转换为数字
    //   const amount = parseFloat(moneyString);

    //   // 检查是否成功转换为数字
    //   if (isNaN(amount)) {
    //     return "Invalid input";
    //   }

    //   // 使用 toLocaleString() 方法添加千分符并保留两位小数
    //   const formattedAmount = amount.toLocaleString("en-US", {
    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    //   });

    //   return formattedAmount;
    // },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    // },

    // 点击查看公告
    viewNotice(data) {
      this.$router.push({
        path: "/Notice/announcementDetails",
        query: {
          auctionCode: data,
        },
      });
    },

    // 规则说明
    viewRules() {
      this.$router.push({
        path: "/Rule",
        // query: {
        //   auctionCode: data,
        // },
      });
    },
  },
};
</script>
<!-- <style>
::v-deep .el-table__header-wrapper .el-table__header thead {
  box-shadow: 0 0 10px 10px rgb(250, 248, 248) !important;
}
</style> -->

<style lang="less" scoped>
/deep/.el-timeline-item__timestamp.is-top {
  padding-top: 0;
}
/deep/.el-input-group__append {
  padding: 0 12px !important;
  background-color: #fff;
}
.svg-icon {
  width: 16px;
  height: 16px;
}
.el-button--medium {
  font-size: 16px;
}
/deep/.el-timeline-item__timestamp {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.85);
}
.el-table td.el-table__cell div {
  color: rgba(0, 0, 0, 0.85);
}
.auctionStatus {
  display: flex;
  align-items: center;
  .circle {
    width: 8px;
    height: 8px;
    background: #02b548;
    border-radius: 50%;
    margin-right: 5px;
  }
  .circle1 {
    background: #ff9300;
  }
  .circle2 {
    background: #fe343a;
  }
  .circle3 {
    background: #d9d9d9;
  }
}
.pagination {
  width: 100%;
  // background-color: #ccc;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.el-button--success {
  background-color: #0dac65;
}

.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
// .line {
//   width: 100%;
//   // height: 2px;
//   border: 1px solid #e0e0e0;
//   margin-top: 32px;
// }

.icon {
  width: 16px;
  height: 16px;
}
.wz {
  margin-left: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #00000066;
}
.price {
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
  color: #000000d9;
}

.price1 {
  color: #ff7f00;
  margin-top: -5px;
  span {
    color: #ff7f00;
    font-size: 22px;
    font-weight: 500;
  }
}
.empty {
  width: 100%;
  height: 200px;
  text-align: center;
  line-height: 200px;
}

.container {
  padding-bottom: 50px;
  min-height: calc(100vh - 300px);
  .topHeader {
    width: 100%;
    height: 200px; /* 让高度自适应，以保持图片的原始纵横比 */
    background: url("../../assets/images/bidProject/jpHeader.png") center/cover
      no-repeat;
    .bannerTit {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      position: relative;
      span {
        position: absolute;
        bottom: 36px;
      }
    }
  }
  .projectContent {
    width: 100%;
    // background-color: #fafafa;

    .toDay {
      width: 1200px;
      margin: 0 auto;

      // margin-top: 50px;
      .smallHeader {
        margin-top: 50px;
        .tit {
          font-weight: 500;
          font-size: 28px;
          color: #000000d9;
          letter-spacing: 0;
        }
      }

      .inProgress {
        margin-top: 30px;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding: 30px 0;
        box-sizing: border-box;
        // .tit {
        //   font-weight: 500;
        //   font-size: 16px;
        //   color: #000000d9;
        //   letter-spacing: 0;
        //   margin-bottom: 20px;
        // }
        .content {
          width: 100%;
          height: 380px;
          background: #ffffff;
          // box-shadow: 0 3px 6px 1px #0000000f;
          display: flex;

          .img {
            width: 53%;
            height: 100%;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .contentDetail {
            // background-color: aquamarine;
            width: 47%;
            height: 100%;
            padding-left: 32px;
            box-sizing: border-box;
            position: relative;
            .header {
              width: 100%;
              height: 72px;
              // padding: 20px;
              box-sizing: border-box;
              .status {
                position: absolute;
                top: 4px;
                width: 84px;
                height: 24px;
                font-weight: 600;
                font-size: 14px;
                color: #ff7f00;
                letter-spacing: 0;
                text-align: center;
                line-height: 24px;
                background: rgba(255, 127, 0, 0.1);
              }
              .title::before {
                content: "";
                display: inline-block;
                width: 94px;
                height: 1px;
                position: static;
              }
              .title {
                line-height: 1.5; /* 调整行高，可以根据需要调整这个值 */
                // white-space: nowrap; /* 不换行 */
                overflow: hidden; /* 隐藏超出部分 */
                text-overflow: ellipsis; /* 用省略号表示超出部分 */
                max-width: 100%; /* 限制宽度不超过父元素宽度 */
                display: -webkit-box;
                -webkit-line-clamp: 2; /* 显示的行数 */
                -webkit-box-orient: vertical;
                font-weight: 500;
                font-size: 22px;
                // color: #000000d9;
                color: rgba(0, 0, 0, 0.85);
              }
            }

            .prices {
              width: 100%;
              height: 100px;
              // background-color: #fff;
              margin-top: 40px;
              display: flex;
              justify-content: space-between;
              .left {
                width: 50%;
                height: 100%;
                // background-color: #ccc;
                .bzj {
                  display: flex;
                  height: 33px;
                  align-items: center;
                }
                .pg {
                  display: flex;
                  height: 33px;
                  align-items: center;
                }
                .dq {
                  display: flex;
                  height: 33px;
                  align-items: center;
                }
              }
              .right {
                width: 50%;
                height: 100%;
                // background-color: #ccc;
                .wz {
                  width: 60px;
                }
                .bd {
                  display: flex;
                  height: 33px;
                  align-items: center;
                }
                .qp {
                  display: flex;
                  height: 33px;
                  align-items: center;
                }
                .js {
                  display: flex;
                  height: 33px;
                  align-items: center;
                  .jjs {
                    letter-spacing: 7px;
                  }
                }
                .pub {
                  margin-left: 20px;
                  color: #000000d9;
                  font-size: 14px;
                }
                .pmbd {
                  flex: 1;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            .btn {
              width: 100%;
              display: flex;
              position: absolute;
              bottom: 0;
              div {
                margin-right: 50px;
              }
              .img1 {
                position: relative;
                img {
                  width: 36px;
                  height: 26px;
                  position: absolute;
                  right: -25px;
                  top: -15px;
                }
              }
            }

            .tel {
              margin-top: 20px;
              font-weight: 400;
              font-size: 14px;
              color: #000000d9;
              span {
                margin-right: 20px;
              }
            }
          }
        }
      }

      .beginMinute {
        width: 100%;
        height: auto;
        margin-top: 30px;
        .search {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          // background: pink;
          .title {
            font-size: 22px;
            font-weight: 500;
            color: #000000d9;
            margin-right: 50px;
          }
          .queryParams {
          }
        }

        .timeLine {
          margin-top: 20px;
          padding-right: 10px;
          padding-top: 10px;
          padding-left: 10px;
          // box-shadow: 0 3px 6px 1px #0000000f;
          .content {
            display: flex;
            .leftImg {
              width: 200px;
              height: 110px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .rightCon {
              width: calc(100% - 200px);
              margin-left: 20px;
              border-bottom: 1px solid #f0f0f0;
              .rightTit {
                width: 100%;
                display: flex;
                height: 55px;
                display: flex;
                // align-items: center;
                // .rightStatus {
                //   width: 84px;
                //   height: 24px;
                //   line-height: 24px;
                //   background: rgba(13, 172, 101, 0.1);
                //   text-align: center;
                //   font-weight: 600;
                //   font-size: 12px;
                //   color: #0dac65;
                // }
                .rightTitle {
                  font-weight: 500;
                  font-size: 22px;
                  color: #000000d9;
                  margin-left: 10px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }

              .rightPrice {
                width: 100%;
                height: 55px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .prices {
                  display: flex;
                  .rightPub {
                    display: flex;
                    margin-right: 30px;
                    align-items: center;
                  }
                }
                .detailBtn {
                  font-weight: 400;
                  font-size: 14px;
                  color: #00000099;
                  vertical-align: middle;
                  cursor: pointer;
                }
                .detailBtn::after {
                  content: ""; /* 必须设置 content 属性，即使内容为空 */
                  display: inline-block;
                  width: 20px; /* 调整图片宽度 */
                  height: 19px; /* 调整图片高度 */
                  background-image: url("../../assets/images/bidProject/next.png"); /* 替换为你的图片路径 */
                  background-size: cover; /* 可根据需要调整背景尺寸 */
                  // margin-right: 5px; /* 可根据需要调整图片与按钮之间的距离 */
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
    }
  }

  .tomorrowBox {
    // padding: 50px 0;

    .tomorrow {
      width: 1200px;
      margin: 0 auto;

      // background-color: rgb(97, 66, 66);
      .tomorrowHeader {
        margin-top: 50px;
        .tomorrowTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            font-weight: 500;
            font-size: 28px;
            color: #000000d9;
            margin-right: 50px;
          }
        }
      }

      .tomorrowContent {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        .contentBox:hover {
          transform: scale(1.02); /* 鼠标移入时放大 20% */
          box-shadow: 0 3px 6px 1px #0000000f;
        }

        .contentBox {
          transition: transform 0.3s ease;
          will-change: transform; /* 提前告诉浏览器会发生 transform，优化性能 */
          width: 305px;
          height: auto;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          margin-bottom: 20px;
          margin-right: 20px;
          cursor: pointer;
          .imgStyle {
            width: 100%;
            height: 170px;
            img {
              width: 100%; /* 让图片的宽度不超过其父元素的宽度 */
              height: 100%; /* 让高度自适应，以保持图片的原始纵横比 */
              display: block; /* 避免图片在父元素内产生底部间隙 */
              margin: auto; /* 在父元素中水平居中 */
            }
          }

          .title {
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            .status {
              position: absolute;
              width: 84px;
              height: 24px;
              background-color: rgba(13, 172, 101, 0.1);
              font-weight: 600;
              font-size: 14px;
              color: #0dac65;
              text-align: center;
              line-height: 24px;
              margin-right: 10px; /* 可以调整间距 */
            }
            .titleStyle::before {
              content: "";
              display: inline-block;
              width: 94px;
              height: 1px;
              // position: static;
            }
            .titleStyle {
              line-height: 1.5; /* 调整行高，可以根据需要调整这个值 */
              // white-space: nowrap; /* 不换行 */
              overflow: hidden; /* 隐藏超出部分 */
              text-overflow: ellipsis; /* 用省略号表示超出部分 */
              max-width: 100%; /* 限制宽度不超过父元素宽度 */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* 显示的行数 */
              -webkit-box-orient: vertical;
              font-weight: 500;
              font-size: 18px;
              color: #000000d9;
            }
          }

          .contentPrice {
            padding-left: 20px;
            .public {
              display: flex;
              margin-bottom: 10px;
              align-items: center;
            }
            .qp,
            .pg,
            .bzj {
              .wz {
                letter-spacing: 7px;
                font-size: 14px;
              }
            }
            .qpTime {
              .wz {
                margin-right: 8px;
                font-size: 14px;
              }
            }
          }
        }
        .contentBox:nth-child(4n) {
          margin-right: 0;
        }

        .more {
          width: 100%;
          height: 20px;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .more::after {
        content: ""; /* 必须设置 content 属性，即使内容为空 */
        display: inline-block;
        width: 20px; /* 调整图片宽度 */
        height: 19px; /* 调整图片高度 */
        background-image: url("../../assets/images/bidProject/more.png"); /* 替换为你的图片路径 */
        background-size: cover; /* 可根据需要调整背景尺寸 */
        // margin-right: 5px; /* 可根据需要调整图片与按钮之间的距离 */
        vertical-align: middle;
      }
    }
  }

  .endBidContent {
    width: 100%;
    // height: 200px;
    // background-image: url("../../assets/images/bidProject/endBidBg.png"); /* 替换为你的图片路径 */
    // background-size: cover; /* 调整背景图大小，cover 表示尽可能覆盖整个容器 */
    // background-position: center; /* 调整背景图位置，使其居中 */
    // background-repeat: no-repeat; /* 防止背景图重复显示 */
    .endBidBox {
      width: 1200px;
      margin: 0 auto;
      margin-top: 50px;
      .endBidHeader {
        .endBidTitle {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .endBidWz {
            font-weight: 500;
            font-size: 28px;
            color: #000000d9;
            margin-right: 50px;
          }
          .search {
          }
        }
      }

      .content {
        width: 100%;
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid #e0e0e0;
      }
    }
  }
}
</style>
